export const addCard = async (cardType, token, newCard) => {
    let url;
  
    switch (cardType) {
      case "ressources":
        url = "https://code-memories.vercel.app/api/ressourcescards";
        break;
      case "notes":
        url = "https://code-memories.vercel.app/api/notescards";
        break;
      case "code":
        url = "https://code-memories.vercel.app/api/codecards";
        break;
      default:
        throw new Error("Type de carte non reconnu");
    }
  
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newCard),
    });
  
    if (!response.ok) {
      throw new Error("Échec de l'ajout de la carte");
    }
  
    return await response.json();
  };