import { FaChevronDown } from "react-icons/fa";
import "./help_content__style.scss";
import PropTypes from "prop-types";

export function CollapseContent({ collapseTitle, collapseText }) {
  return (
    <div className="collapse">
      <input type="checkbox" id={collapseTitle} />
      <label className="collapse-title" htmlFor={collapseTitle}>
        {collapseTitle}
        <FaChevronDown className="chevron-icon" />
      </label>
      <div className="collapse-content">
        <div>{collapseText}</div>
      </div>
    </div>
  );
}

CollapseContent.propTypes = {
  collapseTitle: PropTypes.string.isRequired,
  collapseText: PropTypes.string.isRequired,
};
