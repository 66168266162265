import "../global_pages.scss";
// Components
import ContainerCard from "../../components/Pages_components/MesCodes_components/ContainerCard";
import { useEffect } from "react";
// Affiche la Page "Mes Notes" (page qui doit apparaitre quand on séléctionne l'onglet "mes notes" dans la barre latérale)
export function MesNotes(tabIsActive) {
  useEffect(() => {
    document.title = "Devs Memories | Docs & Notes";
  }, []);

  return (
    <div className="page_content">
      <ContainerCard tabIsActive={tabIsActive} containerType="ContainerNotes" />
    </div>
  );
}
