import { useState, useEffect } from "react";
import "./container.scss";
import { Modal } from "../../Globals_components/Modal_components/Modal";
import { NewItemButton } from "../../Globals_components/NewItem_button_components/NewItemButton";
import { FilterBar } from "../../Globals_components/FilterBar_components/FilterBar";
import { TitleContainer } from "../../Globals_components/TitleContainer_components/TitleContainer";
import { useSearch } from "../../context/SearchContext";
import { useUser } from "../../context/UserContext";
import useCardTitle from "../../../hooks/CardTitle";
import useLoading from "../../../hooks/LoadingCard";
import { fetchCards } from "../../../services/get/fetchCards";
import useModalToggle from "../../../hooks/ModalToggle";
import { addCard } from "../../../services/post/addCard";
import useFilteredCards from "../../../hooks/FilteredCards";
import { deleteCard } from "../../../services/delete/deleteCards";
import useLoadingCardAction from "../../../hooks/LoadingCardAction";
import { LoaderContainer } from "../../Globals_components/LoaderContainerType_components/LoaderContainer";
import { LoaderCardAction } from "../../Globals_components/LoaderCardsActions_components/LoaderCardAction";
import PropTypes from "prop-types";
import CodeCards  from "./CodeCards/CodeCards";
import NotesCard from "./NoteCards/NoteCards";
import RessourcesCards from "./RessourcesCards/RessourcesCards";

export function ContainerCard({ containerType, tabIsActive }) {
  const { title, handleTitleChange } = useCardTitle();
  const [cards, setCards] = useState([]);
  const [newCardTechnos, setNewCardTechnos] = useState([]);
  const [newCardTextAreas, setNewCardTextAreas] = useState("");
  const [dateFilter, setDateFilter] = useState("Les plus récents");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { searchTerm } = useSearch();
  const { user } = useUser();
  const { isModalOpen, handleCloseModal, handleOpenModal } = useModalToggle();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const { isLoadingCardAction, startLoadingCardAction, stopLoadingCardAction } =
    useLoadingCardAction();

  // États spécifiques aux ressources
  const [newVideoUrl, setNewVideoUrl] = useState("");
  const [newVideoImport, setNewVideoImport] = useState([]);

  const loadCards = async (shouldStartLoading = true) => {
    startLoading(shouldStartLoading);
    try {
      const fetchedCards = await fetchCards(user, containerType);
      setCards(fetchedCards);
    } catch (error) {
      console.error("Erreur lors de la récupération des cartes", error);
    } finally {
      stopLoading();
    }
  };
  
  useEffect(() => {
    if (user) {
      loadCards();
    }
  }, [user, tabIsActive]);

  const handleAddCard =   async () => {
    if (user) {
      try {
        const token = await user.getIdToken();
        let newCard = {
          title,
          createdAt: new Date().toISOString(),
          categoriescard: selectedCategory,
        };

        if (containerType === "ContainerCode") {
          newCard.technos = JSON.parse(newCardTechnos);
          newCard.textAreas = JSON.parse(`["${newCardTextAreas}"]`);
        } else if (containerType === "ContainerNotes") {
          newCard.textAreas = JSON.parse(`["${newCardTextAreas}"]`);
        } else if (containerType === "ContainerRessources") {
          newCard.text = newCardTextAreas;
          newCard.videoUrl = newVideoUrl;
          newCard.videoImport = newVideoImport;
        }

        const addedCard = await addCard(
          containerType.toLowerCase().replace("container", ""),
          token,
          newCard
        );
        setCards((prevCards) => [...prevCards, addedCard]);
        handleCloseModal();
      } catch (error) {
        console.error("Erreur lors de l'ajout de la carte:", error);
      }
    }
  };

  const handleDeleteCard = async (cardId) => {
    if (user) {
      try {
        startLoadingCardAction(true);
        await deleteCard(cardId, containerType, user, setCards, cards);
      } catch (error) {
        console.error("Erreur lors de la suppression de la carte:", error);
      } finally {
        stopLoadingCardAction(false);
      }
    }
  };

  const handleSaveCard = async (updatedCardData) => {
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.id === updatedCardData.id ? updatedCardData : card
      )
    );
    handleCloseModal();
  };

  const filteredCards = useFilteredCards(cards, dateFilter, selectedCategory);

  if (isLoading) return <LoaderContainer />;

  const renderCards = () => {
    switch (containerType) {
      case "ContainerCode":
        return (
          <CodeCards
            codecards={filteredCards}
            onDelete={handleDeleteCard}
            searchTerm={searchTerm}
            containerType={containerType}
            onSave={handleSaveCard}
            fetchCards={loadCards}
          />
        );
      case "ContainerNotes":
        return (
          <NotesCard
            notescards={filteredCards}
            onDelete={handleDeleteCard}
            searchTerm={searchTerm}
            onSave={handleSaveCard}
            fetchCardsNotes={loadCards}
            containerType={containerType}
          />
        );
      case "ContainerRessources":
        return (
          <RessourcesCards
            ressourcescards={filteredCards}
            onDelete={handleDeleteCard}
            searchTerm={searchTerm}
            fetchCardsRessources={loadCards}
            onSave={handleSaveCard}
            containerType={containerType}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="container">
      {isLoadingCardAction && <LoaderCardAction />}
      <div className="top-content">
        <TitleContainer
          value={
            containerType === "ContainerCode"
              ? "Collection"
              : containerType === "ContainerNotes"
              ? "Docs & Notes"
              : "Ressources"
          }
        />
        <div className="right-elements">
          <NewItemButton
            onClick={handleOpenModal}
            containerType={containerType}
          />
          <FilterBar
            containerType={containerType}
            onCategorySelect={setSelectedCategory}
            onDateSelect={setDateFilter}
          />
        </div>
      </div>

      <div className="cards-container">{renderCards()}</div>

      <Modal
        isOpen={isModalOpen}
        title={title}
        onTitleChange={handleTitleChange}
        technos={newCardTechnos}
        onTechnosChange={(e) => setNewCardTechnos(e.target.value)}
        textAreaValue={newCardTextAreas}
        onTextAreaChange={(e) => setNewCardTextAreas(e.target.value)}
        videoUrl={newVideoUrl}
        videoFile={newVideoImport}
        onVideoUrlChange={(e) => setNewVideoUrl(e.target.value)}
        onVideoImportChange={(e) => setNewVideoImport(e.target.files)}
        onClose={handleCloseModal}
        onAddCard={handleAddCard}
        showImageInput={true}
        containerType={containerType}
        fetchCards={loadCards}
        setCards={setCards}
      />
    </div>
  );
}

ContainerCard.propTypes = {
  containerType: PropTypes.oneOf([
    "ContainerCode",
    "ContainerNotes",
    "ContainerRessources",
  ]).isRequired,
  tabIsActive: PropTypes.string,
};

export default ContainerCard;
