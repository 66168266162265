import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAC0tkpwWsXUV6mFyePEmgvPROIf7unLsY",
  authDomain: "dev-memories-21f38.firebaseapp.com",
  projectId: "dev-memories-21f38",
  storageBucket: "dev-memories-21f38.appspot.com",
  messagingSenderId: "682245424203",
  appId: "1:682245424203:web:d11ea8e1d79c3d3cc9b9ce",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);

export { app, auth };
