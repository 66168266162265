// Components
import { Logo } from "./logo/Logo.jsx";
import { SearchBar } from "./search_bar/SearchBar.jsx";
import { PanelProfil } from "./panel_profil/PanelProfil.jsx";
import { useState, useEffect } from "react";
// Style
import "./navbar.scss";
// Parent component : Navbar
// Affiche la barre de navigation de l'application.
export function NavBar() {
  const [isMobile, setIsMobile] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className="nav-bar">
      <Logo />
      <SearchBar />
      {isMobile > 751 && <PanelProfil />}
      
    </div>
  );
}
