export const fetchCards = async (user, containerType) => {
  if (!user) {
    throw new Error("Aucun utilisateur authentifié");
  }
  const token = await user.getIdToken();
  let url = "";

  switch (containerType) {
    case "ContainerCode":
      url = "https://code-memories.vercel.app/api/codecards";
      break;
    case "ContainerNotes":
      url = "https://code-memories.vercel.app/api/notescards";
      break;
    case "ContainerRessources":
      url = "https://code-memories.vercel.app/api/ressourcescards";
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.ok) {
    const cards = await response.json();
    return cards.map(card => ({
        ...card,
        createdAt: card.createdAt || null, 
    }));
  } else {
    throw new Error("Erreur lors de la récupération des cards");
  }
};