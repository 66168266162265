import { useState, memo } from "react";
import PropTypes from "prop-types";
import "./category_elements__style.scss";
import { FaChevronDown } from "react-icons/fa";

const CustomSelectFilter = memo(
  ({ options, selectedOption, onOptionSelect , isDateFilter }) => {
    const [state, setState] = useState({
      isOpen: false,
      isAnimated: false,
    });

    // Option par défaut
    let defaultOption = "Aucune";
    const allOptionToutes = "Toutes";
    const allOptions = [allOptionToutes, defaultOption, ...options];
    const allOptionsDateRecent = 'Les plus récents'
    const allOptionDateAncient = "Les moins récents"
    const dateOptions = [allOptionsDateRecent,allOptionDateAncient];
 

    const handleToggleSelect = () => {
      setState((prev) => ({
        isOpen: !prev.isOpen,
        isAnimated: !prev.isAnimated,
      }));
    };

    const handleOptionClick = (option) => {
      onOptionSelect(option);
      setState({ isOpen: false, isAnimated: false });
    };

    return (
      <div className="custom-select-container">
      <div
        className={`custom-select-selected ${
          state.isAnimated ? "custom-select-animated" : ""
        }`}
        onClick={handleToggleSelect}
      >
        {selectedOption } 
        <span className="chevron-select">
          <FaChevronDown />
        </span>
      </div>
      {state.isOpen && (
        <div className="custom-select-options">
          {isDateFilter ? dateOptions.map((option, index) => ( // Affiche les options de date si isDateFilter === true
            <div
              key={index}
              className="custom-select-option"
              onClick={() => handleOptionClick(option)} // Met à jour l'option sélectionnée
            >
              {option}
            </div>
          )) : allOptions.map((option, index) => ( // Sinon afficher les option par défaut
            <div
              key={index}
              className="custom-select-option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
    );
  }
);

CustomSelectFilter.displayName = "CustomSelectFilter";

CustomSelectFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOption: PropTypes.string,
  onOptionSelect: PropTypes.func.isRequired,
  isDateFilter : PropTypes.bool
};

export default CustomSelectFilter;
