import { useState, useEffect } from "react";
import { useUser } from "../../components/context/UserContext";
import "./login_page__style.scss";
import { auth } from "../../config/firebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";


export function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pseudo, setPseudo] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [isLogin, setIsLogin] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const { refreshUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const lastActiveTab = localStorage.getItem("lastActiveTab") || "Accueil";
        navigate(`/${lastActiveTab}`);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(()=>{
    document.title = 'Devs Memories | Connexion'
  },[])

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/;
    return re.test(String(password));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setMessage("");

    if (!email || !password) {
      setErrorMessage("Veuillez entrer une adresse email et un mot de passe.");
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage("Veuillez entrer un email valide.");
      return;
    }

    if (!isLogin && !validatePassword(password)) {
      setErrorMessage(
        "Le mot de passe doit contenir au moins 8 caractères, une majuscule et un caractère spécial."
      );
      return;
    }

    try {
      if (isLogin) {
        await signInWithEmailAndPassword(auth, email, password);
        setMessage("Connexion réussie !");
      } else {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
  
        await updateProfile(user, {
          displayName: pseudo,
          photoURL: avatar ? URL.createObjectURL(avatar) : null,
        });
  
        await user.reload();
        refreshUser();
  
        await sendEmailVerification(user);
        setMessage("Inscription réussie ! Veuillez vérifier votre email pour confirmer votre compte.");
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const toggleMode = () => {
    setIsLogin(!isLogin);
    setErrorMessage("");
    setMessage("");
  };

  const handleAvatarChange = (e) => {
    if (e.target.files[0]) {
      setAvatar(e.target.files[0]);
    }
  };

  return (
    <div className="login-page_container">
      <h2>{isLogin ? "Se connecter" : "Créer un compte"}</h2>
      <form onSubmit={handleSubmit}>
        <div className="champs">
          <label className="email">
            Email:
            <input
              className="input-champ-login"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
        </div>
        <div className="champs">
          <label className="password">
            Mot de passe:
            <input
              className="input-champ-login"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
        </div>
        {!isLogin && (
          <>
            <div className="champs">
              <label className="pseudo">
                Pseudo:
                <input
                  className="input-champ-login"
                  type="text"
                  value={pseudo}
                  onChange={(e) => setPseudo(e.target.value)}
                  required
                />
              </label>
            </div>
            <div className="champs">
              <label className="image-profil-loggin">
                Image profil:
                <input
                  className="input-image-file-login"
                  type="file"
                  accept="image/*"
                  onChange={handleAvatarChange}
                />
              </label>
            </div>
          </>
        )}
        <div className="login-btns">
          <button type="submit">
            {isLogin ? "Se connecter" : "Créer un compte"}
          </button>
          {message && <p className="success-message">{message}</p>}
          {errorMessage && <p className="error-message-login">{errorMessage}</p>}
          <button type="button" onClick={toggleMode}>
            {isLogin ? "S'inscrire" : "J'ai déjà un compte"}
          </button>
        </div>
      </form>
    </div>
  );
}