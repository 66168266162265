import { useState } from "react";

const useLoadingCardAction = () => {
  const [isLoadingCardAction, setIsLoadingCardAction] = useState(false);

  const startLoadingCardAction = (shouldStart = true) => {
    if (shouldStart) {
        setIsLoadingCardAction(true);
    }
  };

  const stopLoadingCardAction = () => setIsLoadingCardAction(false);

  return { isLoadingCardAction, startLoadingCardAction, stopLoadingCardAction };
};

export default useLoadingCardAction;
