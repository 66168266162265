import { AboutContent } from "../../components/Pages_components/About_components/AboutContent";
import "../global_pages.scss";
import { useEffect } from "react";

export function About() {

useEffect(()=>{
document.title = 'Devs Memories | A propos'
},[])

  return (
    <div className="page_content">
      <AboutContent />
    </div>
  );
}
