import { useState } from "react"

 const useCardTitle = ()=>{
    const [title , setTitle] = useState('')

    const handleTitleChange = (e) =>{
        setTitle(e.target.value)
    }

    return {title , handleTitleChange}
}

export default useCardTitle;