import "../global_pages.scss";
import { AccueilContent } from "../../components/Pages_components/Accueil_components/AccueilContent";
import { useEffect } from "react";



export function Accueil() {

useEffect(()=>{
  document.title = "Devs Memories | Accueil"
},[])

  return (
    <div className="page_content">
      <AccueilContent />
    </div>
  );
}
