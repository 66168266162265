import { ButtonsCard } from "../../../Globals_components/ButtonsCard_components/ButtonsCard";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { CardsContentSelectContext } from "../../../context/CardsContentSelectContext";
import { ModalModifyCard } from "../../../Globals_components/Modal_components/ModalModifyCard";
import PropTypes from "prop-types";
import "./codecards.scss";

export default function CodeCards({
  codecards = [],
  onDelete,
  searchTerm,
  fetchCards,
  onSave,
}) {
  const navigate = useNavigate();
  const { setSelectedCard } = useContext(CardsContentSelectContext);
  const [modalModifyOpen, setModalModifyOpen] = useState(false);

  const handleOpen = async (cardId) => {
    const card = codecards.find((card) => card.id === cardId);
    if (card) {
      setSelectedCard({ ...card, type: "code" });
      navigate(`/Collection/details/${cardId}`);
    } else {
      console.error("Carte non trouvée:", cardId);
    }
  };

  const handleOpenModalModify = (cardId) => {
    const card = codecards.find((card) => card.id === cardId);
    if (card) {
      localStorage.setItem("selectedCardId", cardId);
      setSelectedCard({ ...card, type: "code" });
      setModalModifyOpen(true);
    }
  };

  const handleCloseModalModify = (cardId) => {
    localStorage.removeItem("selectedCardId", cardId);
    setModalModifyOpen(false);
  };

  if (!Array.isArray(codecards)) {
    console.error("codecards n'est pas un tableau:", codecards);
    return <div>Erreur: codecards n&aposest pas un tableau</div>;
  }

  const filteredCards = codecards.filter(
    (card) =>
      card.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      card.technos.some((techno) =>
        techno.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  return (
    <div className="cards_container_collection">
      {[...filteredCards].reverse().map((card, index) => (
        <div
          className="card"
          key={index}
          style={{ order: codecards.length - index }}
        >
          <div className="card-header">
            <h3 className="card-title">{card.title}</h3>
            <div className="buttons-card">
              <ButtonsCard
                cardId={card.id}
                onDelete={onDelete}
                onOpen={() => handleOpen(card.id)}
                onModify={() => handleOpenModalModify(card.id)}
              />
            </div>
          </div>
          <div className="card-body" onClick={() => handleOpen(card.id)}>
            <div className="categorie">
              <span>Catégorie: </span>
              <p>{card.categoriescard}</p>
            </div>
            <div className="image-container">
              {card.imageUrl && card.imageUrl[0] ? (
                <img
                  src={card.imageUrl[0]}
                  alt={card.title}
                  className="card-image"
                />
              ) : (
                <div className="no-image">Aucune image</div>
              )}
            </div>
          </div>
          <div className="card-footer">
            <div className="technos">
              {card.technos.map((techno, index) => (
                <span key={index} className="techno-tag">
                  {techno}
                </span>
              ))}
            </div>
          </div>
        </div>
      ))}
      <ModalModifyCard
        isOpen={modalModifyOpen}
        onClose={handleCloseModalModify}
        onSave={onSave}
        containerType="ContainerCode"
        fetchCards={fetchCards}
      />
    </div>
  );
}

CodeCards.propTypes = {
  codecards: PropTypes.array,
  id: PropTypes.string,
  title: PropTypes.string,
  technos: PropTypes.arrayOf(PropTypes.string),
  textAreas: PropTypes.arrayOf(PropTypes.string),
  imageUrl: PropTypes.array,

  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  fetchCards: PropTypes.func,
};
