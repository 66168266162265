import PropType from "prop-types";
import "./title-container__style.scss";
/**
 * Affiche le titre en fonction du ContainerType
 * @param {string}
 */

export function TitleContainer({ value }) {
  return <h1>{value}</h1>;
}

TitleContainer.propTypes = {
  value: PropType.string.isRequired,
};
