import { useState, useEffect } from "react";
import "./login__style.scss";
import { CiLogin, CiLogout } from "react-icons/ci";
import { Link } from "react-router-dom";
import { auth } from "../../../../config/firebaseConfig";
import { onAuthStateChanged, signOut } from "firebase/auth";

export function Login() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Erreur lors de la déconnexion", error);
    }
  };

  return (
    <div className="login-container">
      {isLoggedIn ? (
        <span className="login-icon-deconnecte" onClick={handleLogout}>
          <CiLogout />
          <span className="login-text-deconnecte">Déconnexion</span>
        </span>
      ) : (
        <Link to="Login">
          <span className="login-icon">
            <CiLogin />
          </span>
          <span className="login-text">Connexion</span>
        </Link>
      )}
    </div>
  );
}
 