import { IoAddOutline } from "react-icons/io5";
import "./new_item_button__style.scss";
import PropTypes from "prop-types";

/**
 * Composant NewItemButton
 * @param {Object} props
 * @param {Function} props.onClick
 * @param {string} props.containerType
 * @returns {JSX.Element}
 */
export function NewItemButton({ onClick, containerType }) {
  if (containerType === "ContainerCode") {
    return (
      <button
        className="button-new"
        title="Créer une nouvelle card"
        onClick={onClick}
      >
        Snippet <IoAddOutline className="plus" />
      </button>
    );
  }

  if (containerType === "ContainerNotes") {
    return (
      <button
        className="button-new"
        title="Créer une nouvelle card"
        onClick={onClick}
      >
        Note <IoAddOutline className="plus" />
      </button>
    );
  }

  if (containerType === "ContainerRessources") {
    return (
      <button
        className="button-new button-new-ressources"
        title="Créer une nouvelle card"
        onClick={onClick}
      >
        Ressources <IoAddOutline className="plus" />
      </button>
    );
  }

  return null;
}

NewItemButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  containerType: PropTypes.string.isRequired,
};
