import PropTypes from "prop-types";
import { useState, useContext, useEffect } from "react";
import { CardsContentSelectContext } from "../../context/CardsContentSelectContext";
import "./modal-modify-card__style.scss";
import { getAuth } from "firebase/auth";

export function ModalModifyCard({
  isOpen,
  onClose,
  onSave,
  containerType,
  fetchCardsRessources,
  fetchCardsNotes,
  fetchCards,
}) {
  const { selectedCard } = useContext(CardsContentSelectContext);
  const [title, setTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [technos, setTechnos] = useState([]);
  const [subtitles, setSubtitles] = useState([]);
  const [textAreas, setTextAreas] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedCard) {
      setTitle(selectedCard.title || "");
      setVideoUrl(selectedCard.videoUrl || "");

      if (containerType === "ContainerCode") {
        setTechnos(
          Array.isArray(selectedCard.technos) ? selectedCard.technos : []
        );
      }

      setSubtitles(
        Array.isArray(selectedCard.subtitles) ? selectedCard.subtitles : []
      );
      setTextAreas(
        Array.isArray(selectedCard.textAreas) ? selectedCard.textAreas : []
      );
    }
  }, [selectedCard, containerType]);

  useEffect(() => {
    if (
      containerType === "ContainerRessources" ||
      containerType === "ContainerNotes" ||
      containerType === "ContainerCode"
    ) {
      const storedCardId = localStorage.getItem("selectedCardId");
      if (storedCardId) {
        if (containerType === "ContainerRessources") {
          fetchCardsRessources();
        } else if (containerType === "ContainerNotes") {
          fetchCardsNotes();
        } else if (containerType === "ContainerCode") {
          fetchCards();
        }
      }
    }
  }, [containerType, fetchCardsRessources, fetchCardsNotes, fetchCards]);

  const handleSave = async () => {
    const cardId = localStorage.getItem("selectedCardId");
    if (!cardId) return;

    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      setErrorMsg("Utilisateur non authentifié.");
      return;
    }

    try {
      setIsLoading(true);
      const token = await user.getIdToken();

      if (!title) {
        setErrorMsg("Le titre est requis.");
        setIsLoading(false);
        return;
      }

      const requestBody = {
        title,
        videoUrl,
        technos:
          containerType === "ContainerCode"
            ? JSON.stringify(technos)
            : undefined,
        subtitles:
          containerType === "ContainerNotes" ||
          containerType === "ContainerRessources"
            ? JSON.stringify(subtitles)
            : undefined,
        textAreas: JSON.stringify(textAreas),
      };

      const url =
        containerType === "ContainerRessources"
          ? `https://code-memories.vercel.app/api/ressourcescards/${cardId}`
          : containerType === "ContainerNotes"
          ? `https://code-memories.vercel.app/api/notescards/${cardId}`
          : containerType === "ContainerCode"
          ? `https://code-memories.vercel.app/api/codecards/${cardId}`
          : null;

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const updatedCardData = await response.json();
        onSave(updatedCardData);
        if (containerType === "ContainerRessources") {
          fetchCardsRessources();
        } else if (containerType === "ContainerNotes") {
          fetchCardsNotes();
        } else if (containerType === "ContainerCode") {
          fetchCards();
        }
        onClose();
      } else {
        setErrorMsg("Erreur lors de la mise à jour des données.");
      }
    } catch (error) {
      console.error("Save Error:", error);
      setErrorMsg("Erreur lors de la mise à jour des données.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleTechnosChange = (index, value) => {
    const updatedTechnos = [...technos];
    updatedTechnos[index] = value;
    setTechnos(updatedTechnos);
  };

  const handleSubtitlesChange = (index, value) => {
    const updatedSubtitles = [...subtitles];
    updatedSubtitles[index] = value;
    setSubtitles(updatedSubtitles);
  };

  const handleTextAreasChange = (index, value) => {
    const updatedTextAreas = [...textAreas];
    updatedTextAreas[index] = value;
    setTextAreas(updatedTextAreas);
  };

  if (!isOpen || !selectedCard) return null;

  return (
    <>
      {isOpen && (
        <div className="modal-backdrop" onClick={onClose}>
          {isLoading && (
            <div className="loader-container">
              <span className="loader-modal"></span>{" "}
            </div>
          )}
          <div
            className="modal-content-edit"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>Modifier la carte</h2>
            {errorMsg && <p className="error-msg">{errorMsg}</p>}
            <form>
              <div className="form-group">
                <label htmlFor="title">Modifier le titre :</label>
                <input
                  id="title"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              {containerType === "ContainerRessources" && (
                <div className="form-group">
                  <label htmlFor="videoUrl">
                    Modifier l&apos;URL de la Vidéo :
                  </label>
                  <input
                    id="videoUrl"
                    type="text"
                    value={videoUrl}
                    onChange={(e) => setVideoUrl(e.target.value)}
                  />
                </div>
              )}
              {/* ContainerCode */}
              {containerType === "ContainerCode" &&
                Array.isArray(technos) &&
                Array.isArray(textAreas) &&
                technos.length === textAreas.length &&
                technos.map((techno, index) => (
                  <div className="modal-input-container-edit" key={index}>
                    <span>Modifier la technologie :</span>
                    <input
                      type="text"
                      value={techno}
                      onChange={(e) =>
                        handleTechnosChange(index, e.target.value)
                      }
                      className="modal-input-edit"
                    />
                    <div className="modal-textarea-container-edit">
                      <span>Modifier le contenu :</span>
                      <textarea
                        value={textAreas[index]}
                        onChange={(e) =>
                          handleTextAreasChange(index, e.target.value)
                        }
                        className="modal-text-area-edit"
                      />
                    </div>
                  </div>
                ))}
              {/* ContainerNotes et ContainerRessources */}
              {(containerType === "ContainerNotes" ||
                containerType === "ContainerRessources") &&
                Array.isArray(subtitles) &&
                Array.isArray(textAreas) &&
                subtitles.length === textAreas.length &&
                subtitles.map((subtitle, index) => (
                  <div className="modal-input-container-edit" key={index}>
                    <span>Modifier le sous-titre :</span>
                    <input
                      type="text"
                      value={subtitle}
                      onChange={(e) =>
                        handleSubtitlesChange(index, e.target.value)
                      }
                      className="modal-input-edit"
                    />
                    <div className="modal-textarea-container-edit">
                      <span>Modifier le contenu :</span>
                      <textarea
                        value={textAreas[index]}
                        onChange={(e) =>
                          handleTextAreasChange(index, e.target.value)
                        }
                        className="modal-text-area-edit"
                      />
                    </div>
                  </div>
                ))}
              <div className="modal-edit-btns">
                <button
                  type="button"
                  onClick={handleSave}
                  disabled={isLoading}
                  className="modal-modifier-btn"
                >
                  Modifier
                </button>
                <button onClick={onClose} className="modal-annuler-btn">
                  Annuler
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

ModalModifyCard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  containerType: PropTypes.string.isRequired,
  fetchCardsRessources: PropTypes.func,
  fetchCardsNotes: PropTypes.func,
  fetchCards: PropTypes.func,
};
