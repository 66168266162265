import { useState } from "react";

const useLoading = () => {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = (shouldStart = true) => {
    if (shouldStart) {
      setIsLoading(true);
    }
  };

  const stopLoading = () => setIsLoading(false);

  return { isLoading, startLoading, stopLoading };
};

export default useLoading;
