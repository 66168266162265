// Import des types PropTypes pour la validation des props
import PropTypes from "prop-types";

// Import des composants
import { ButtonsCard } from "../../../Globals_components/ButtonsCard_components/ButtonsCard";

// Import des styles
import "./ressources-array__style.scss";

// Import des hooks React
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { useState, useEffect } from "react";
import { memo } from "react";
// Import du contexte pour gérer les cartes sélectionnées
import { CardsContentSelectContext } from "../../../context/CardsContentSelectContext"; // Import du composant ReactPlayer pour lire les vidéos
import ReactPlayer from "react-player";
import { ModalModifyCard } from "../../../Globals_components/Modal_components/ModalModifyCard";

export const RessourcesCards = memo(function RessourcesCards({
  ressourcescards = [],
  onDelete,
  onSave,
  searchTerm,
  fetchCardsRessources,
}) {
  const navigate = useNavigate();

  const { setSelectedCard } = useContext(CardsContentSelectContext);

  const [modalModifyOpen, setModalModifyOpen] = useState(false);

  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowVideo(true);
    }, 600);
    return () => clearTimeout(timer);
  }, []);

  /**
   * Ouvre une carte spécifique en naviguant vers la page de détails
   * @param {string} cardId - Identifiant de la carte à ouvrir
   */
  const handleOpen = (cardId) => {
    const card = ressourcescards.find((card) => card.id === cardId);
    if (card) {
      setSelectedCard({ ...card, type: "ressources" });
      navigate(`/Ressources/details/${cardId}`);
    }
  };

  const handleOpenModalModify = (cardId) => {
    const card = ressourcescards.find((card) => card.id === cardId);
    if (card) {
      localStorage.setItem("selectedCardId", cardId);
      setSelectedCard({ ...card, type: "ressources" });
      setModalModifyOpen(true);
    }
  };

  const handleCloseModalModify = (cardId) => {
    localStorage.removeItem("selectedCardId", cardId);
    setModalModifyOpen(false);
  };

  if (!Array.isArray(ressourcescards)) {
    console.error("ressourcescards n'est pas un tableau:", ressourcescards);
    return <div>Erreur : ressourcescards n&apos;est pas un tableau</div>;
  }

  const filteredCards = ressourcescards.filter(
    (card) =>
      card.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      card.subtitles.some((subtitles) =>
        subtitles.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  return (
    <div className="cards_container_ressources">
      {[...filteredCards].map((card, index) => (
        <div className="ressource-card" key={index} style={{ order: index }}>
          <div className="ressource-card-elements">
            <div className="top-contain-ressource">
              <h3>{card.title}</h3>
              <div className="buttons">
                <ButtonsCard
                  cardId={card.id}
                  onDelete={() => onDelete(card.id)}
                  onOpen={() => handleOpen(card.id)}
                  onModify={() => handleOpenModalModify(card.id)}
                />
              </div>
              <div className="category">
                <span>
                  Catégorie :{" "}
                  <span className="category-name">{card.categoriescard}</span>
                </span>
              </div>
            </div>

            <div
              className="ressources-other-content"
              onClick={() => {
                handleOpen(card.id);
              }}
            >
              {card.videoUrl && showVideo ? (
                <div className="video-player">
                  <ReactPlayer url={card.videoUrl} width="100%" />
                </div>
              ) : (
                !card.videoUrl && (
                  <div>
                    <img src={card.imageUrl[0]} />
                  </div>
                )
              )}

              {card.videoUrl.length === 0 && card.imageUrl.length === 0 ? (
                <div className="no-image">Aucune image</div>
              ) : null}

              <div className="bottom-contain-ressource">
                {card.subtitles.map((subtitle, i) => (
                  <div key={i}>
                    <span>{subtitle}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}{" "}
      <ModalModifyCard
        isOpen={modalModifyOpen}
        onClose={handleCloseModalModify}
        containerType="ContainerRessources"
        onSave={onSave}
        fetchCardsRessources={fetchCardsRessources}
      />
    </div>
  );
});

// PropTypes pour RessourcesCards
RessourcesCards.propTypes = {
  ressourcescards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitles: PropTypes.arrayOf(PropTypes.string).isRequired,
      videoUrl: PropTypes.string,
    })
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  fetchCardsRessources: PropTypes.func,
  searchTerm: PropTypes.string.isRequired,
};

export default RessourcesCards;
