import "../Accueil_components/accueil-content__style.scss";

export function AboutContent() {
  return (
    <div className="accueil-content">
      <div className="accueil-top-content">
        <h1>À propos de DevMemories</h1>
        <p>
          Je suis Lyes Azzouz, développeur front-end, et j&apos;ai créé
          DevMemories pour résoudre un problème récurrent que je rencontrais
          dans ma pratique quotidienne. En tant que développeur, je me trouvais
          souvent submergé par une multitude d&apos;onglets ouverts :
          documentations officielles, cours en ligne, tutoriels, snippets de
          code (souvent sur CodePen), ainsi que des notes éparpillées entre
          diverses applications et des blocs-notes. Cette dispersion me poussait
          à passer un temps considérable à chercher des ressources, et il
          m&apos;arrivait fréquemment de perdre des informations précieuses en
          raison de la fermeture accidentelle de mes onglets de navigateur.
        </p>
      </div>

      <section className="accueil-middle-content">
        <div className="accueil-explications-app">
          <h2>Technologies Utilisées</h2>
          <p>
            Pour développer DevMemories, j&apos;ai utilisé une combinaison de
            technologies modernes adaptées aux besoins de l&apos;application :
          </p>
          <ul>
            <li>
              <strong>React :</strong> En tant que bibliothèque JavaScript pour
              la construction d&apos;interfaces utilisateur, React me permet de
              créer des composants interactifs et réactifs avec une gestion
              efficace de l&apos;état et des rendus. Sa popularité et sa
              flexibilité en font un choix idéal pour créer des applications web
              dynamiques et performantes.
            </li>
            <li>
              <strong>Node.js et Express.js :</strong> Ces technologies côté
              serveur sont utilisées pour gérer la logique de l&apos;application
              et les interactions avec la base de données. Node.js, avec sa
              capacité à gérer des opérations asynchrones, et Express.js, avec
              sa simplicité et sa robustesse, permettent de construire une API
              efficace et scalable pour DevMemories.
            </li>
            <li>
              <strong>Firebase :</strong> Ce service est utilisé pour
              l&apos;authentification, le stockage de données et la gestion des
              utilisateurs. Firebase offre une solution intégrée et sécurisée
              pour les fonctionnalités en temps réel et le stockage des données,
              ce qui simplifie le développement tout en assurant une bonne
              performance et une grande évolutivité.
            </li>
            <li>
              <strong>Sass :</strong> Le préprocesseur CSS utilisé pour une
              gestion améliorée des styles. Sass permet une meilleure
              organisation des styles avec des fonctionnalités comme les
              variables, les mixins et les fonctions, rendant le code CSS plus
              maintenable et modulaire.
            </li>
            <li>
              <strong>React Router :</strong> Utilisé pour gérer la navigation
              et le routage au sein de l&apos;application. Cela permet de créer
              des routes dynamiques et d&apos;améliorer l&apos;expérience
              utilisateur avec une gestion fluide des différentes vues de
              l&apos;application.
            </li>
            <li>
              <strong>Firebase Admin :</strong> Utilisé pour les opérations côté
              serveur, comme la gestion des utilisateurs et des autorisations,
              garantissant une sécurité et une gestion des données robustes.
            </li>
            <li>
              <strong>React Icons :</strong> Utilisé pour intégrer des icônes
              vectorielles dans l&apos;application. Cette bibliothèque offre un
              large éventail d&apos;icônes personnalisables et légères qui
              améliorent l&apos;interface utilisateur.
            </li>
            <li>
              <strong>React Player :</strong> Intègre des lecteurs multimédia
              dans l&apos;application, permettant de gérer des vidéos et des
              audios de manière fluide et réactive.
            </li>
            <li>
              <strong>Highlight.js :</strong> Fournit la coloration syntaxique
              pour les extraits de code, rendant le code plus lisible et
              esthétiquement agréable.
            </li>
            <li>
              <strong>UUID :</strong> Génère des identifiants uniques pour les
              éléments de l&apos;application, garantissant leur unicité et
              facilitant la gestion des données.
            </li>
          </ul>
        </div>

        <div className="accueil-explications-objectif">
          <h2>Inspiration et Design</h2>
          <p>
            Pour le design de DevMemories, je me suis inspiré d&apos;une
            maquette pour la conception de la nav-bar et de la side-bar. Le
            reste du design a été entièrement conçu et personnalisé par mes
            soins pour répondre spécifiquement aux besoins des utilisateurs et
            offrir une expérience fluide et agréable.
          </p>
          <p>
            DevMemories intègre ces technologies et bibliothèques pour offrir
            une solution robuste, réactive et facile à utiliser. Cette
            combinaison permet de créer une application performante qui
            centralise les ressources de développement et améliore
            l&apos;efficacité des développeurs.
          </p>
        </div>
      </section>
    </div>
  );
}
