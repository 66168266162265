// Style
import "./buttons_card.scss";
// React icons
import { RiDeleteBin6Fill } from "react-icons/ri";
// import { BiSolidMessageSquareEdit } from "react-icons/bi";
import { MdOpenInNew } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

import PropTypes from "prop-types";

/**
 * Components ButtonsCard.
 * @param {Object} props
 * @param {string} props.cardId
 * @param {Function} props.onDelete
 * @param {Function} props.onOpen
 */

export function ButtonsCard({ cardId, onDelete, onOpen, onModify }) {
  return (
    <div className="btn-card__container">
      {/* Bouton de suppression */}
      <button id="btn-1" title="Supprimer" onClick={() => onDelete(cardId)}>
        <div className="btn-icon">
          <RiDeleteBin6Fill />
        </div>
      </button>
      <button id="btn-3" title="Modifier" onClick={() => onModify(cardId)}>
        <div className="btn-icon">
          <FaRegEdit />
        </div>
      </button>
      {/* Bouton d'ouverture (Doit rediriger vers une autre page.) */}
      <button id="btn-2" title="Ouvrir" onClick={() => onOpen(cardId)}>
        <div className="btn-icon">
          <MdOpenInNew />
        </div>
      </button>
    </div>
  );
}

ButtonsCard.propTypes = {
  cardId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
};
