import { useState, useCallback, memo } from "react";
import {
  getAuth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateProfile,
  deleteUser,
} from "firebase/auth";
import PropTypes from "prop-types";
import "./panel-profil__style.scss";

const EditProfile = memo(function EditProfile({
  isOpen,
  onRequestClose,
  accountDelete,
  setAccountDelete,
  userConnected,
}) {
  const [newPseudo, setNewPseudo] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteMessageClass, setDeleteMessageClass] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const auth = getAuth();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const user = auth.currentUser;

      if (user) {
        try {
          await updateProfile(user, {
            displayName: newPseudo || user.displayName,
          });

          onRequestClose();
        } catch (error) {
          console.error("Erreur lors de la mise à jour du profil:", error);
        }
      }
    },
    [newPseudo, onRequestClose, auth]
  );

  const handleDeleteAccount = useCallback(async () => {
    const user = auth.currentUser;

    if (!user) {
      console.log("Aucun utilisateur n'est connecté.");
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      await reauthenticateWithCredential(user, credential);
      await deleteUser(user);
      console.log("Votre compte a bien été supprimé !");
      setAccountDelete(true);
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.log("Erreur lors de la suppression.", error.message);
    }

    handleMessageConfirmationToggle();
  }, [currentPassword, setAccountDelete, auth]);

  const handleButtonsDeleteAccount = useCallback(() => {
    const user = auth.currentUser;

    if (user) {
      setShowDeleteConfirmation(true);
    } else {
      console.log("Aucun utilisateur connecté.");
    }
  }, [auth]);

  const handleEnterKeyDown = useCallback((event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }, []);

  const handleMessageConfirmationToggle = useCallback(() => {
    setDeleteMessageClass("animate-in");

    setTimeout(() => {
      setDeleteMessageClass("animate-out");
    }, 1300);

    setTimeout(() => {
      setDeleteMessageClass("");
    }, 1700);
  }, []);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onRequestClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Modifier le profil</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Nom:
              <input
                type="text"
                value={newPseudo}
                onChange={(e) => setNewPseudo(e.target.value)}
              />
            </label>
          </div>
          <div className="buttons-edit">
            <button type="submit" className="btn-edit-one">
              Enregistrer
            </button>
            <button
              type="button"
              className="btn-edit-two"
              onClick={onRequestClose}
            >
              Annuler
            </button>
          </div>
          <div className="delete-user">
            {userConnected && (
              <button
                type="button"
                className="delete-account-btn"
                onClick={handleButtonsDeleteAccount}
              >
                Supprimer mon compte
              </button>
            )}

            {showDeleteConfirmation && (
              <div className="last-message-overlay">
                <div className="last-message-delete">
                  <p>
                    Pour confirmer la suppression de votre compte, entrez votre
                    mot de passe:
                  </p>
                  <input
                    type="password"
                    placeholder="Mot de passe"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    onKeyDown={handleEnterKeyDown}
                  />
                  <div className="btns-delete-account">
                    <button
                      type="button"
                      onClick={handleDeleteAccount}
                      className="btn-delete-account"
                    >
                      Oui, supprimer mon compte
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowDeleteConfirmation(false)}
                      className="btn-delete-account-cancel"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            )}

            {accountDelete && (
              <div className="delete-message">
                {" "}
                {deleteMessageClass && (
                  <span className="delete-message-confirmation">
                    Votre compte a bien été supprimé !
                  </span>
                )}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
});

EditProfile.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  accountDelete: PropTypes.bool.isRequired,
  setAccountDelete: PropTypes.func.isRequired,
  userConnected: PropTypes.bool.isRequired,
};

export default EditProfile;
