// Import des icônes React pour les différents onglets de la barre latérale
import { IoIosCodeWorking, IoIosHelpCircleOutline } from "react-icons/io";
import { SlNote } from "react-icons/sl";
import { GrResources } from "react-icons/gr";
import { GoHome, GoInfo } from "react-icons/go";

// Import des composants locaux utilisés dans la barre latérale
import { Tabs } from "./Tabs/Tabs.jsx";
import { Login } from "./login/Login.jsx";

// Import des hooks React et des composants pour la gestion de la nav
import { useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";

// Import de PropTypes pour la validation des propriétés
import PropTypes from "prop-types";

// Import des styles spécifiques à la barre latérale
import "./sidebar.scss";

/**
 * Composant Sidebar
 * @param {Object} props
 * @param {String} props.tabIsActive
 * @param {Function} props.setTabIsActive
 * @returns {JSX.Element}
 */
export function SideBar({ tabIsActive, setTabIsActive }) {
  const location = useLocation(); // Obtient l'emplacement actuel de la route

  useEffect(() => {
    const storedTab = localStorage.getItem("activeTab");

    if (storedTab) {
      setTabIsActive(storedTab);
    } else {
      switch (true) {
        case location.pathname === "/":
          setTabIsActive("Accueil");
          break;
        case location.pathname.includes("Collection"):
          setTabIsActive("Collection");
          break;
        case location.pathname.includes("Docs-et-notes"):
          setTabIsActive("Docs-et-notes");
          break;
        case location.pathname.includes("Ressources"):
          setTabIsActive("Ressources");
          break;
        case location.pathname.includes("Aide"):
          setTabIsActive("Aide");
          break;
        case location.pathname.includes("About"):
          setTabIsActive("A propos");
          break;
        default:
          setTabIsActive("Accueil");
      }
    }
  }, [location.pathname, setTabIsActive]);

  const handleClickTab = useCallback(
    (tabName) => {
      localStorage.setItem("activeTab", tabName);
      localStorage.setItem("lastActiveTab", tabName);
      setTabIsActive(tabName);
    },
    [setTabIsActive]
  );

  return (
    <div className="side-bar">
      <div className="all-tabs">
        <Link to="/" onClick={() => handleClickTab("Accueil")}>
          <Tabs
            textContent="Accueil"
            icon={<GoHome />}
            isActive={tabIsActive === "Accueil"}
          />
        </Link>
        <Link to="/aide" onClick={() => handleClickTab("Aide")}>
          <Tabs
            textContent="Aide"
            icon={<IoIosHelpCircleOutline />}
            isActive={tabIsActive === "Aide"}
          />
        </Link>
        <Link to="/about" onClick={() => handleClickTab("About")}>
          <Tabs
            textContent="A propos"
            icon={<GoInfo />}
            isActive={tabIsActive === "About"}
          />
        </Link>
        <Link to="/collection" onClick={() => handleClickTab("Collection")}>
          <Tabs
            textContent="Collection"
            icon={<IoIosCodeWorking />}
            isActive={tabIsActive === "Collection"}
          />
        </Link>
        <Link
          to="/docs-et-notes"
          onClick={() => handleClickTab("Docs-et-notes")}
        >
          <Tabs
            textContent="Docs et Notes"
            icon={<SlNote />}
            isActive={tabIsActive === "Docs-et-notes"}
          />
        </Link>
        <Link to="/ressources" onClick={() => handleClickTab("Ressources")}>
          <Tabs
            textContent="Ressources"
            icon={<GrResources />}
            isActive={tabIsActive === "Ressources"}
          />
        </Link>

        <Login />
      </div>
    </div>
  );
}

// Définition des PropTypes pour valider les propriétés passées au composant
SideBar.propTypes = {
  tabIsActive: PropTypes.string.isRequired, // Nom de l'onglet actuellement actif (obligatoire)
  setTabIsActive: PropTypes.func.isRequired, // Fonction pour mettre à jour l'onglet actif (obligatoire)
};
