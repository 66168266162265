// src/PrivateRoute.jsx
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useUser } from "./components/context/UserContext";

const PrivateRoute = ({ element, path, tabIsActive }) => {
  const { user } = useUser();

  if (!user) {
    // Stocke la route et l'onglet actif dans le localStorage
    if (path) {
      localStorage.setItem("redirectAfterLogin", path);
      if (tabIsActive) {
        localStorage.setItem("activeTab", tabIsActive);
      }
    }
    return <Navigate to="/Login" />;
  }

  return element;
};

PrivateRoute.propTypes = {
  element: PropTypes.element.isRequired,
  path: PropTypes.string,
  tabIsActive: PropTypes.string, // Ajoutez la prop `tabIsActive`
};

export default PrivateRoute;
