import { useState  } from "react";
import { CgProfile } from "react-icons/cg";
import "./panel-profil__style.scss";
import { Link } from "react-router-dom";
import EditProfile from "./EditProfil";
import { useUser } from "../../../context/UserContext";


export const PanelProfil = () => {
  const { user } = useUser();
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  const [accountDelete, setAccountDelete] = useState(false);
  const [showMessagePanelProfilEdit, setShowMessagePanelProfilEdit] = useState(false);





    const handleEditProfile = () => {
      if (user) {
        setIsEditProfileOpen(true);
        setShowMessagePanelProfilEdit(false);
      } else {
        setShowMessagePanelProfilEdit(true);
      }
    }

  const handleCloseEditProfile = () => {
    setIsEditProfileOpen(false);
    setAccountDelete(false);
  }

  const handleCloseMessagePanelProfil =() => {
    setShowMessagePanelProfilEdit(false);
  }

  return (
    <div className="panel_profil_container">
      <div className="panel_profil">
        <div className="image_profil">
          <CgProfile className="image-defaut" />
        </div>
        <div className="profil">
          <div className="name_profil">
          {user?.displayName || "Compte"}
          </div>
          <button className="edit_profil" onClick={handleEditProfile}>
            Modifier
          </button>
        </div>
      </div>

      {showMessagePanelProfilEdit && (
        <div className="panel-profil-no-auth">
          <div className="message-panel-profil">
            <p>
              Vous devez vous inscrire ou vous connecter pour pouvoir modifier
              votre profil.
            </p>
            <div className="panel-profil-no-auth-btns">
              <Link to="Login">
                <button
                  className="message-panel-profil-link"
                  onClick={handleCloseMessagePanelProfil}
                >
                  S&apos;identifier
                </button>
              </Link>
              <button
                onClick={handleCloseMessagePanelProfil}
                className="message-panel-profil-close"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}

      <EditProfile
        isOpen={isEditProfileOpen}
        onRequestClose={handleCloseEditProfile}
        accountDelete={accountDelete}
        setAccountDelete={setAccountDelete}
        userConnected={!!user}
      />
    </div>
  );
};
