import "./collapse_content__style.scss";
import { CollapseContent } from "./CollapseContent";

export function HelpContent() {
  return (
    <div className="help-content">
      {/* Section Titre et Descriptif */}
      <section className="help-title-section">
        <h1>Aide</h1>
        <p>
          Cette page est conçue pour vous aider à utiliser Devs Memories. Vous
          trouverez ci-dessous des instructions détaillées sur les
          fonctionnalités de l&apos;application et des réponses aux questions
          fréquentes.
        </p>
      </section>

      {/* Section Collapse */}
      <section className="help-collapses"></section>

      <CollapseContent
        collapseTitle="Comment créer une carte de sauvegarde ?"
        collapseText={
          <div className="collapse-text">
            <span className="collapse-text__intro">
              Vous avez trois onglets principaux dans l&apos;application :
            </span>
            <ul className="collapse-text__list">
              <li className="collapse-text__list-item">
                <strong className="collapse-text__list-title">
                  Collection :
                </strong>
                Utilisez cet onglet pour stocker et organiser vos snippets de
                code. Vous pouvez y inclure des solutions trouvées en ligne
                (comme sur StackOverflow), des créations personnelles, ou
                générées par une IA. Bref, tout type de code dont il serait
                judicieux de sauvegarder.
              </li>
              <li className="collapse-text__list-item">
                <strong className="collapse-text__list-title">
                  Docs et Notes :
                </strong>
                Conservez ici toutes vos notes et documents liés à vos projets.
                Cela peut inclure des mémos pour des commandes Git courantes,
                des ReadMe, ou des extraits de documentation officielle. Vous
                pourrez centraliser vos références et annotations pour un accès
                facile.
              </li>
              <li className="collapse-text__list-item">
                <strong className="collapse-text__list-title">
                  Ressources :
                </strong>
                Stockez vos ressources ici, telles que des tutoriels vidéo ou
                écrits, des articles, etc. Ajoutez vos propres annotations,
                exercices, ou explications pour personnaliser les ressources
                selon vos besoins.
              </li>
            </ul>
            <div className="collapse-text__demo">
              <span className="collapse-text__demo-title">Démonstration :</span>
              <p className="collapse-text__demo-description">
                Quelle que soit le type de carte que vous ajoutez, la procédure
                est similaire. Suivez ces étapes :
              </p>
              <div className="collapse-text__guide">
                <div className="collapse-text__guide-step collapse-text__guide-step--one">
                  <span className="collapse-text__guide-step-text">
                    1. Ouvrez la modale. Pour l&apos;ouvrir, il vous suffit
                    simplement d&apos;appuyer sur le bouton situé à gauche de la
                    barre de filtre. Pour l&apos;onglet Collection, le bouton
                    sera : <strong>Snippet</strong>. Pour
                    <strong>Docs et Notes</strong>, le bouton sera : Note. Pour
                    l&apos;onglet Ressources, le bouton sera:{" "}
                    <strong>Ressources</strong>.
                  </span>
                  <img
                    className="collapse-text__guide-step-image"
                    src="/aide_images/modal_aide_ouverte.png"
                    alt="Modale Ouverte"
                  />
                </div>
                <div className="collapse-text__guide-step collapse-text__guide-step--two">
                  <span className="collapse-text__guide-step-text">
                    2. Ajoutez un titre et, si souhaité, des images illustrant
                    votre contenu. Les images sont optionnelles mais
                    recommandées pour plus de clarté.
                  </span>
                  <img
                    className="collapse-text__guide-step-image"
                    src="/aide_images/Modal_aide_code_1.png"
                    alt="Modale Ouverte"
                  />
                </div>
                <div className="collapse-text__guide-step collapse-text__guide-step--three">
                  <span className="collapse-text__guide-step-text">
                    3. Sélectionnez ou créez une catégorie pour la carte. Vous
                    pouvez aussi choisir l&apos;option par défaut
                    &quot;Aucune&quot;.
                  </span>
                  <img
                    className="collapse-text__guide-step-image"
                    src="/aide_images/categorie_aide_1.png"
                    alt="Catégories Aide"
                  />
                </div>
                <div className="collapse-text__guide-step collapse-text__guide-step--four">
                  <span className="collapse-text__guide-step-text">
                    4.{" "}
                    <strong>Pour l&apos;onglet Ressources seulement :</strong>{" "}
                    Ajoutez une URL de vidéo. Si vous suivez un tutoriel vidéo,
                    collez simplement le lien dans le champ prévu. La vidéo sera
                    accessible directement depuis la carte.
                  </span>
                  <img
                    className="collapse-text__guide-step-image"
                    src="/aide_images/video_url_aide.png"
                    alt="Vidéo Aide"
                  />
                </div>
                <div className="collapse-text__guide-step collapse-text__guide-step--five">
                  <span className="collapse-text__guide-step-text">
                    5. Indiquez la technologie utilisée pour les snippets (pour
                    l&apos;onglet Collection) ou un sous-titre pertinent pour
                    les autres onglets. Par exemple, pour un mémo sur les
                    commandes npm, utilisez un sous-titre tel que &quot;npm run
                    build&quot;.
                  </span>
                  <span>Pour les Snippets:</span>
                  <img
                    className="collapse-text__guide-step-image"
                    src="/aide_images/input_code_aide.png"
                    alt="Vidéo Aide"
                  />
                  <span>Pour les docs, notes et ressources:</span>
                  <img
                    className="collapse-text__guide-step-image"
                    src="/aide_images/input_st_aide.png"
                    alt="Vidéo Aide"
                  />
                </div>
                <div className="collapse-text__guide-step collapse-text__guide-step--six">
                  <span className="collapse-text__guide-step-text">
                    6. Remplissez la zone de texte avec les détails nécessaires.
                    Pour ajouter du code, encapsulez-le entre les balises&nbsp;
                    <strong>!cd!</strong>&nbsp; et&nbsp; <strong>!cf!</strong>
                    &nbsp;pour qu&apos;il soit formaté et coloré correctement.
                    <br></br>
                    Vous pouvez en ajouter jusqu&apos;à 6 pour les sauvegardes
                    les plus complexes. Il vous suffit d&apos;appuyer sur le
                    bouton&nbsp; <strong>&quot;+&quot;</strong> &nbsp;en bas de
                    chaque zone de texte. Pour les supprimer, appuyez sur le
                    bouton <strong>croix</strong> au-dessus de chaque zone de
                    texte.
                  </span>
                  <img
                    className="collapse-text__guide-step-image"
                    src="/aide_images/aide_textarea.png"
                    alt="TextArea Aide"
                  />
                  <img
                    className="collapse-text__guide-step-image"
                    src="/aide_images/multiarea_aide.png"
                    alt="TextArea Aide 2"
                  />
                </div>
                <div className="collapse-text__guide-step collapse-text__guide-step--seven">
                  <span className="collapse-text__guide-step-text">
                    7. Soumettez le formulaire en appuyant sur le bouton dédié
                    en bas de la modale.
                  </span>
                  <img
                    className="collapse-text__guide-step-image"
                    src="/aide_images/submit_aide.png"
                    alt="Submit Aide"
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
      <CollapseContent
        collapseTitle="Comment Modifier son profil ?"
        collapseText={
          <div className="collapse-text">
            <span className="collapse-text__intro">
              Vous pouvez modifier votre image de profil ainsi que votre pseudo
              :
            </span>
            <div className="collapse-text__demo">
              <span className="collapse-text__demo-title">Démonstration :</span>
              <p className="collapse-text__demo-description">
                C&apos;est très simple, il vous suffit de cibler le panneau de
                profil situé en haut à droite de la page , cliquer sur modifier
                , une fênetre modale s&apos;ouvrira vous permettant ainsi de
                modifier votre profil.
              </p>
              <div className="collapse-text__guide">
                <div className="collapse-text__guide-step collapse-text__guide-stet--one">
                  <span className="collapse-text__guide-step-text">
                    Cliquez sur modifier :
                  </span>
                  <img
                    className="collapse-text__guide-step-image"
                    src="/aide_images/panel_profil_1.png"
                    alt="Panneau de profil 1"
                  />
                  <span className="collapse-text__guide-step-text">
                    Modifier votre profil :
                  </span>
                  <img
                    className="collapse-text__guide-step-image"
                    src="/aide_images/panel_profil_2.png"
                    alt="Vidéo Aide"
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
      <CollapseContent
        collapseTitle="Comment s'inscrire , se connecter et se déconnecter ?"
        collapseText={
          <div className="collapse-text">
            <span className="collapse-text__intro">
              Pour vous inscrire , rien de plus simple :
            </span>

            <div className="collapse-text__demo">
              <span className="collapse-text__demo-title">Démonstration :</span>
              <p className="collapse-text__demo-description">
                Lorsque vous arriverz sur Devs Memories, vous ne pourrez pas
                accèder aux onglets suivant : <strong>Collection</strong>,
                <strong>Docs et Notes</strong>,<strong>Ressources</strong>.Il
                faut que vous créez un compte en amont afin d&apos;avoir accès à
                ces onglets , si vous tentez d&apos;y accèder , vous serez
                automatiquement redirigé vers la page de connexion.
              </p>
              <div className="collapse-text__guide">
                <div className="collapse-text__guide-step collapse-text__guide-step--one">
                  <span className="collapse-text__guide-step-text">
                    1. Pour vous inscrire, il faudra cliquer sur le bouton
                    &quot;S&apos;inscrire&quot;.
                  </span>
                  <img
                    className="collapse-text__guide-step-image"
                    src="/aide_images/aide_inscription_1.png"
                    alt="Aide inscription"
                  />
                </div>
                <div className="collapse-text__guide-step collapse-text__guide-step--two">
                  <span className="collapse-text__guide-step-text">
                    2. Ensuite, vous aurez accès au formulaire
                    d&apos;inscription que vous devriez compléter.
                  </span>
                  <img
                    className="collapse-text__guide-step-image"
                    src="/aide_images/aide_inscription_2.png"
                    alt="Aide inscription"
                  />
                </div>
                <div className="collapse-text__guide-step collapse-text__guide-step--three">
                  <span className="collapse-text__guide-step-text">
                    3. Cliquez sur : &ldquo;Créer un compte&ldquo; pour créer
                    votre compte , si vous avez déjà un compte vous pouvez
                    cliquer sur &ldquo;J&apos;ai déjà un compte&ldquo;. Vous
                    aurez accès aux différents onglets de l&ldquo;application.
                  </span>
                  <img
                    className="collapse-text__guide-step-image"
                    src="/aide_images/aide_inscription_3.png"
                    alt="Aide inscription"
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
      {/* Section Feedback */}
      <section className="feedback-section">
        <h2>Comment trouvez-vous Devs Memories ?</h2>
        <p>
          J&apos;apprécie vos retours ! N&apos;hésitez pas à partager vos avis
          sur l&apos;application pour m&apos;aider à l&apos;améliorer.
        </p>
        <textarea
          className="feedback-textarea"
          placeholder="Donnez votre avis ici... La logique de soumission de ce forumlaire n'est pas encore implémentée."
        />
        <button className="feedback-submit">Envoyer</button>
      </section>
    </div>
  );
}
