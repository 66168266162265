import { lazy, Suspense } from 'react';
import "./modal__style.scss";
import PropTypes from "prop-types";
const LazyModalContent = lazy(() => import('./ModalContent'));

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddCard: PropTypes.func,
  showImageInput: PropTypes.bool,
  textAreaValue: PropTypes.string,
  onTextAreaChange: PropTypes.func,
  technos: PropTypes.array,
  onTechnosChange: PropTypes.func,
  imageFile: PropTypes.instanceOf(File),
  videoFile: PropTypes.array,
  onImageFileChange: PropTypes.func,
  onVideoImportChange: PropTypes.func,
  containerType: PropTypes.string.isRequired,
  subtitles: PropTypes.arrayOf(PropTypes.string),
  fetchCards: PropTypes.func,
  fetchCardsNotes: PropTypes.func,
  fetchCardsRessources: PropTypes.func,
  videoUrl: PropTypes.string,
  onVideoUrlChange: PropTypes.func,
  codeSetTitle: PropTypes.func,
  notesSetTitle: PropTypes.func,
  ressourcesSetTitle: PropTypes.func,
};

export function Modal({
  isOpen,
  title,
  onTitleChange,
  onClose,
  showImageInput,
  videoUrl,
  onVideoUrlChange,
  containerType,
  fetchCards,
  fetchCardsNotes,
  fetchCardsRessources,
  ressourcesSetTitle,
}) {
  if (!isOpen) return null;

  return (
    <Suspense>
      <LazyModalContent
        isOpen={isOpen}
        title={title}
        onTitleChange={onTitleChange}
        onClose={onClose}
        showImageInput={showImageInput}
        videoUrl={videoUrl}
        onVideoUrlChange={onVideoUrlChange}
        containerType={containerType}
        fetchCards={fetchCards}
        fetchCardsNotes={fetchCardsNotes}
        fetchCardsRessources={fetchCardsRessources}
        ressourcesSetTitle={ressourcesSetTitle}
      />
    </Suspense>
  );
}

export default Modal;