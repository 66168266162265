import { useState, useEffect, useCallback } from "react";
import { getAuth } from "firebase/auth";
import CustomSelectFilter from "./CustomSelectFilter";
import { MdFilterList } from "react-icons/md";
import "./filterbar__style.scss";
import PropTypes from "prop-types";
import { fetchCategories } from "../../../services/get/fetchCategories";

// import { FaChevronDown } from "react-icons/fa";

export const FilterBar = ({ containerType, onCategorySelect , onDateSelect }) => {
  const [state, setState] = useState({
    selectedCategorie: "Filtrer par catégories",
    categories: [],
    isExpend: false,
    selectedDate : "Filter par date"
  });


  const auth = getAuth();
  const user = auth.currentUser;

  const handleToggleExpand = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isExpend: !prev.isExpend,
    }));
  }, []);

  const handleCategorieDisplay = useCallback(async () => {
    if (!user) return;

    try {
      const data = await fetchCategories(user, containerType);
      setState((prev) => ({
        ...prev,
        categories: data,
      }));
    } catch (error) {
      console.error("Erreur lors de la récupération des catégories", error);
    }
  }, [containerType, user]);

  useEffect(() => {
    handleCategorieDisplay();
  }, [handleCategorieDisplay]);

  const handleCategorySelect = useCallback(
    (category) => {
      console.log(`Catégorie séléctionnée: ${category}`); // Log pour suivre la catégorie sélectionnée
      setState((prev) => ({
        ...prev,
        selectedCategorie: category,
      }));
      if (onCategorySelect) {
        onCategorySelect(category); // Passer la catégorie  au parent
      }
    },
    [onCategorySelect]
  );

  return (
    <div
      className={`filter-bar_container ${
        state.isExpend ? "filter-bar_container-expand" : "filter-bar-container"
      }`}
    >
      <span className="icon" onClick={handleToggleExpand}>
        {<MdFilterList />}
      </span>
      {state.isExpend && (
        <div className="filter-elements">
          <div className="filter-categorie">
            <div className="custom-filtered">
              <CustomSelectFilter
                options={state.categories.map((category) => category.name)}
                selectedOption={state.selectedCategorie}
                onOptionSelect={handleCategorySelect}
              />
              <CustomSelectFilter
                options={[]} 
                selectedOption={state.selectedDate} 
                onOptionSelect={(selectedDate) => {
                  console.log(`Filter de date séléctioné: ${selectedDate}`);
                  setState((prev) => ({
                    ...prev,
                    selectedDate: selectedDate, 
                  }));
                  onDateSelect(selectedDate); 
                }} 
                isDateFilter={true} 
             
              />
            </div>
          </div>
        
        </div>
      )}
      <span className="text">Filtrer</span>
    </div>
  );
};

FilterBar.propTypes = {
  containerType: PropTypes.string.isRequired,
  onCategorySelect: PropTypes.func,
  onDateSelect : PropTypes.func
};
