import "../global_pages.scss";
// Components
import ContainerCard from "../../components/Pages_components/MesCodes_components/ContainerCard";
import PropTypes from "prop-types";
import { useEffect } from "react";
// Affiche la Page "Mes Codes" (page qui doit apparaitre quand on séléctionne l'onglet mes codes dans la barre latérale)
export function MesCodes({ tabIsActive }) {
  useEffect(() => {
    document.title = "Devs Memories | Collection";
  }, []);

  return (
    <div className="page_content">
      <ContainerCard tabIsActive={tabIsActive} containerType="ContainerCode" />
    </div>
  );
}

MesCodes.propTypes = {
  tabIsActive: PropTypes.string,
};
