// src/App.jsx
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "../src/App.scss";
import { SideBar } from "./components/Globals_components/SideBar_components/SideBar.jsx";
import { NavBar } from "./components/Globals_components/NavBar_components/NavBar.jsx";
import { MesCodes } from "./pages/MesCodes_page/MesCodes.jsx";
import { MesNotes } from "./pages/MesNotes_page/MesNotes.jsx";
import { Ressources } from "./pages/Ressources_page/Ressources.jsx";
import { Accueil } from "./pages/Accueil_page/Accueil.jsx";
import { CardContentDetail } from "./pages/CardContentDetail_page/CardContentDetail.jsx";
import { LoginPage } from "./pages/Login_page/LoginPage.jsx";
import { About } from "./pages/About_page/About.jsx";
import { CardsContentProvider } from "./components/context/CardsContentSelectContext.jsx";
import { ModalProvider } from "./components/context/ModalContext.jsx";
import { UserProvider } from "./components/context/UserContext.jsx";
import PrivateRoute from "./PrivateRoute.jsx";
import { useState } from "react";
import { Aide } from "./pages/Help_page/Aide.jsx";


function App() {
  const [tabIsActive, setTabIsActive] = useState("");

  return (
    
    <UserProvider>
      <CardsContentProvider>
        <ModalProvider>
          <Router>
            <NavBar />
            <SideBar
              tabIsActive={tabIsActive}
              setTabIsActive={setTabIsActive}
            />
            <Routes>
              <Route path="/Accueil" element={<Accueil />} />
              <Route path="/About" element={<About />} />
              <Route path="/" element={<Accueil tabIsActive={tabIsActive} />} />
              <Route
                path="/Collection"
                element={
                  <PrivateRoute element={<MesCodes />} path="/Collection" />
                }
              />
              <Route
                path="/Collection/details/:id"
                element={
                  <PrivateRoute
                    element={<CardContentDetail />}
                    path="/Collection/detail/:id"
                  />
                }
              />
              <Route
                path="/Docs-et-notes/details/:id"
                element={
                  <PrivateRoute
                    element={<CardContentDetail />}
                    path="/Docs-et-notes/detail/:id"
                  />
                }
              />
              <Route
                path="/Docs-et-notes"
                element={
                  <PrivateRoute
                    element={<MesNotes tabIsActive={tabIsActive} />}
                    path="/Docs-et-notes"
                  />
                }
              />
              <Route
                path="/Ressources"
                element={
                  <PrivateRoute
                    element={<Ressources tabIsActive={tabIsActive} />}
                    path="/Ressources"
                  />
                }
              />
              <Route
                path="/Ressources/details/:id"
                element={
                  <PrivateRoute
                    element={<CardContentDetail />}
                    path="/Ressources/detail/:id"
                  />
                }
              />
              <Route
                path="/Aide"
                element={<Aide tabIsActive={tabIsActive} />}
              />

              <Route path="/Login" element={<LoginPage />} />
            </Routes>
          </Router>
        </ModalProvider>
      </CardsContentProvider>
    </UserProvider>
  );
}

export default App;
