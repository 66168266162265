// Import des PropTypes pour la validation des propriétés du composant
import PropTypes from "prop-types";

// Import du composant ButtonsCard pour les boutons des cartes
import { ButtonsCard } from "../../../Globals_components/ButtonsCard_components/ButtonsCard";

// Import des styles spécifiques pour le composant NotesCards
import "./notes-array__style.scss";

// Import du hook useNavigate pour la navigation entre les pages
import { useNavigate } from "react-router-dom";

// Import du hook useContext pour accéder au contexte React
import { useContext } from "react";
import { useState } from "react";
import { ModalModifyCard } from "../../../Globals_components/Modal_components/ModalModifyCard";

// Import du contexte pour la gestion des cartes sélectionnées
import { CardsContentSelectContext } from "../../../context/CardsContentSelectContext";
/**
 * Composant pour afficher les cartes de notes
 * @param {Object} props - Les propriétés passées au composant.
 * @param {Array} props.notescards - Les données des cartes à afficher.
 * @param {Function} props.onDelete - La fonction pour supprimer une carte.
 * @param {Function} props.onSelectCard - La fonction pour sélectionner une carte.
 */
export default function NotesCards({
  notescards = [],
  onDelete,
  searchTerm,
  onSave,
  fetchCardsNotes,
}) {
  // Hook pour la navigation
  const navigate = useNavigate();

  // Accès au contexte pour gérer la sélection des cartes
  const { setSelectedCard } = useContext(CardsContentSelectContext);

  const [modalModifyOpen, setModalModifyOpen] = useState(false);

  // Fonction pour ouvrir une carte au clic
  const handleOpen = (cardId) => {
    const card = notescards.find((card) => card.id === cardId);
    if (card) {
      setSelectedCard({ ...card, type: "notes" });
      // Navigation vers la page de détail
      navigate(`/Docs-et-notes/details/${cardId}`);
    } else {
      console.error("Carte non trouvée:", cardId);
    }
  };

  const handleOpenModalModify = (cardId) => {
    const card = notescards.find((card) => card.id === cardId);
    if (card) {
      localStorage.setItem("selectedCardId", cardId);
      setSelectedCard({ ...card, type: "notes" });
      setModalModifyOpen(true);
    }
  };

  const handleCloseModalModify = (cardId) => {
    localStorage.removeItem("selectedCardId", cardId);
    setModalModifyOpen(false);
  };

  if (!Array.isArray(notescards)) {
    console.error("notescards n'est pas un tableau:", notescards);
    return <div>Erreur: notescards n&aposest pas un tableau</div>;
  }

  const filteredCards = notescards.filter(
    (card) =>
      card.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      card.subtitles.some((subtitles) =>
        subtitles.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  return (
    <div className="cards_container">
      {[...filteredCards]
        .slice()
        .reverse()
        .map((card, index) => (
          <div
            className="notes-card"
            key={index}
            style={{ order: notescards.length - index }}
          >
            <div className="notes-card-elements">
              <div className="top-contain-notes">
                <div className="title-and-category">
                  <h3>{card.title}</h3>
                  <div className="categorie">
                    <span>Catégorie : </span>
                    <p>{card.categoriescard}</p>
                  </div>
                </div>

                <ButtonsCard
                  id="btn-notes"
                  cardId={card.id}
                  onDelete={onDelete}
                  onOpen={() => handleOpen(card.id)}
                  onModify={() => handleOpenModalModify(card.id)}
                />
              </div>
              <div
                className="bottom-contain-notes"
                onClick={() => {
                  handleOpen(card.id);
                }}
              >
                {card.imageUrl && card.imageUrl[0] && (
                  <div className="notes-images">
                    <img src={card.imageUrl[0]} alt={card.title} />
                  </div>
                )}

                {card.imageUrl.length === 0 ? (
                  <div className="no-image">Aucune image</div>
                ) : null}

                {card.subtitles.map((subtitle, i) => (
                  <span key={i} className="subtitle-span">
                    {subtitle}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}
      <ModalModifyCard
        isOpen={modalModifyOpen}
        onClose={handleCloseModalModify}
        onSave={onSave}
        containerType="ContainerNotes"
        fetchCardsNotes={fetchCardsNotes}
      />
    </div>
  );
}

NotesCards.propTypes = {
  notescards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitles: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  fetchCardsNotes: PropTypes.func,
};

