// Import des styles spécifiques au composant CodeCardContent
import "./card-content__style.scss";

// Import des bibliothèques nécessaires
import hljs from "highlight.js"; // Pour la coloration syntaxique du code
import "highlight.js/styles/atom-one-dark.css"; // Style de coloration syntaxique pour highlight.js
import { useEffect } from "react"; // Hook pour les effets secondaires dans les composants fonctionnels
import PropTypes from "prop-types"; // Pour la validation des propriétés
import { CarouselCard } from "../../../../Globals_components/CarouselCard_components/CarouselCard";

/**
 * Composant pour afficher le contenu détaillé d'une carte de code
 *
 * @param {Object} props - Les propriétés passées au composant
 * @param {string} props.title - Le titre de la carte
 * @param {Array} props.technos - Les technologies associées à la carte
 * @param {Array} props.textAreas - Les zones de texte à afficher
 * @param {string} props.imageUrl - L'URL de l'image à afficher
 */

// Fonction pour colorer et formater le texte entouré de !code!
const renderTextWithCodeHighlighting = (text) => {
  const regex = /!cd!(.*?)!cf!/gs;
  const parts = text.split(regex);

  return parts.map((part, index) => {
    if (index % 2 === 1) {
      // Les segments impairs sont du code
      const codeContent = part.trim(); // Supprime les espaces inutiles
      return (
        <pre key={index}>
          <code className="hljs">{codeContent}</code>
        </pre>
      );
    } else {
      // Les segments pairs sont du texte normal
      return (
        <pre key={index} className="normal-text">
          {part}
        </pre>
      );
    }
  });
};

export function CodeCardContent({ title, technos, textAreas, imageUrl }) {
  useEffect(() => {
    document.querySelectorAll("pre code").forEach((block) => {
      hljs.highlightElement(block);
    });
  }, [textAreas]);

  return (
    <div className="card-content_container">
      <div className="top-content">
        <div className="card-content_top-text">
          {/* Affichage du titre de la carte */}
          <h3>{title}</h3>
        </div>

        <CarouselCard imageUrl={imageUrl} showVideo={false} />
      </div>

      <div className="card-content_code">
        {technos &&
          textAreas &&
          technos.length === textAreas.length &&
          technos.map((techno, index) => (
            <div key={index} className="card-content_code-block">
              {/* Affichage de chaque technologie */}
              <h4 className="techno">{techno}</h4>
              <div className="content-card">
                {renderTextWithCodeHighlighting(textAreas[index])}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

// Définition des PropTypes pour valider les propriétés passées au composant
CodeCardContent.propTypes = {
  title: PropTypes.string.isRequired, // Titre de la carte (obligatoire)
  technos: PropTypes.arrayOf(PropTypes.string).isRequired, // Technologies associées à la carte (obligatoire)
  textAreas: PropTypes.arrayOf(PropTypes.string).isRequired, // Zones de texte à afficher (obligatoire)
  imageUrl: PropTypes.string.isRequired, // URL de l'image associée à la carte (obligatoire)
};
