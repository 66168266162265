import "../global_pages.scss";
import ContainerCard from "../../components/Pages_components/MesCodes_components/ContainerCard";
import { useEffect } from "react";
//// Affiche la Page "Ressources" (page qui doit apparaitre quand on séléctionne l'onglet "Ressources" dans la barre latérale)

export function Ressources(tabIsActive) {
  useEffect(() => {
    document.title = "Devs Memories | Ressources";
  }, []);

  return (
    <div className="page_content">
      <ContainerCard
        tabIsActive={tabIsActive}
        containerType="ContainerRessources"
      />
    </div>
  );
}
