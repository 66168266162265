import { createContext, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  getAuth,
  setPersistence,
  onAuthStateChanged,
  browserLocalPersistence,
} from "firebase/auth";

export const CardsContentSelectContext = createContext();

export const CardsContentProvider = ({ children, containerType }) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [categories, setCategories] = useState([]);

  const updateSelectedCard = useCallback((updatedCard) => {
    setSelectedCard((prevCard) =>
      prevCard && prevCard.id === updatedCard.id ? updatedCard : prevCard
    );
  }, []);

  const fetchCategories = useCallback(async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.log("Utilisateur non connecté, impossible de récupérer les catégories");
      return;
    }

    try {
      const token = await user.getIdToken();
      let url = "";

      switch (containerType) {
        case "ContainerCode":
          url = "https://code-memories.vercel.app/api/categoriescode";
          break;
        case "ContainerNotes":
          url = "https://code-memories.vercel.app/api/categoriesnotes";
          break;
        case "ContainerRessources":
          url = "https://code-memories.vercel.app/api/categoriesressources";
          break;
        default:
          throw new Error("Type de conteneur non reconnu");
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Réponse d'erreur:", errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Categories data received:", data);
      setCategories(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des catégories:", error);
      setCategories([]);
    }
  }, [containerType]);

  const fetchSelectedCard = useCallback(async () => {
    const cardId = localStorage.getItem("selectedCardId");
    if (!cardId) return;

    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.log("Utilisateur non connecté, impossible de récupérer la carte sélectionnée");
      return;
    }

    try {
      const token = await user.getIdToken();
      const url = `https://code-memories.vercel.app/api/ressourcescards/${cardId}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Réponse d'erreur:", errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Selected card data received:", data);
      setSelectedCard(data);
    } catch (error) {
      console.error("Erreur lors de la récupération de la carte:", error);
      setSelectedCard(null);
      localStorage.removeItem("selectedCardId");
    }
  }, []);

  useEffect(() => {
    const auth = getAuth();

    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            fetchCategories();
            fetchSelectedCard();
          } else {
            console.log("Aucun utilisateur connecté");
            setSelectedCard(null);
            setCategories([]);
            localStorage.removeItem("selectedCardId");
          }
        });

        return () => unsubscribe();
      })
      .catch((error) => {
        console.error("Erreur lors de la configuration de la persistance:", error);
      });
  }, [fetchCategories, fetchSelectedCard]);

  const handleLogout = useCallback(async () => {
    const auth = getAuth();
    try {
      await auth.signOut();
      setSelectedCard(null);
      setCategories([]);
      localStorage.removeItem("selectedCardId");
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
    }
  }, []);

  return (
    <CardsContentSelectContext.Provider
      value={{
        selectedCard,
        setSelectedCard,
        categories,
        fetchCategories,
        updateSelectedCard,
        fetchSelectedCard,
        handleLogout,
      }}
    >
      {children}
    </CardsContentSelectContext.Provider>
  );
};

CardsContentProvider.propTypes = {
  children: PropTypes.node.isRequired,
  containerType: PropTypes.string,
};