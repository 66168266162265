import { ContainerCardContent } from "../../components/Pages_components/CardContentDetail_components/ContainerCardContent";
import "../global_pages.scss";
import { useEffect } from "react";

export function CardContentDetail() {
  useEffect(() => {
    document.title = "Devs Memories | Sauvegarde";
  }, []);

  return (
    <div className="page_content_detail">
      <ContainerCardContent />
    </div>
  );
}
