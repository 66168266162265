import { createContext, useState, useContext } from "react";
import PropTypes from "prop-types";

const SearchContext = createContext();

export function SearchProvider({ children }) {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <SearchContext.Provider value={{ searchTerm, setSearchTerm }}>
      {children}
    </SearchContext.Provider>
  );
}

export function useSearch() {
  return useContext(SearchContext);
}

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
