import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./carousel-card__style.scss";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ReactPlayer from "react-player";

export function CarouselCard({ imageUrl = [], videoUrl }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageUrl.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imageUrl.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleShowVideo = () => {
    const container = document.querySelector(".carousel-container");
    container.classList.remove("show-content");
    container.classList.add("hide-content");

    setTimeout(() => {
      setIsVideoVisible(true);
      setIsAnimationComplete(false);
      setIsClosing(false);
    }, 500);
  };

  const handleCloseVideo = () => {
    setIsClosing(true);
    const videoElement = document.querySelector(".carousel-slide-video");
    videoElement.style.height = "0";
    videoElement.style.backgroundColor = "transparent";

    setTimeout(() => {
      setIsVideoVisible(false);
      setIsAnimationComplete(false);
      const container = document.querySelector(".carousel-container");
      container.classList.remove("hide-content");
      container.classList.add("show-content");
    }, 500);
  };

  useEffect(() => {
    const videoElement = document.querySelector(".carousel-slide-video");
    if (videoElement) {
      if (isVideoVisible && !isClosing) {
        videoElement.style.height = "30vw";
        videoElement.style.backgroundColor = "#000";
      } else if (isClosing) {
        videoElement.style.height = "0";
        videoElement.style.backgroundColor = "transparent";
      }
    }
  }, [isVideoVisible, isClosing]);

  useEffect(() => {
    let timer;
    if (isVideoVisible && !isAnimationComplete && !isClosing) {
      timer = setTimeout(() => {
        setIsAnimationComplete(true);
      }, 500);
    }

    return () => clearTimeout(timer);
  }, [isVideoVisible, isAnimationComplete, isClosing]);

  const showControls = imageUrl.length > 1 && !isVideoVisible;

  return (
    <div className="carousel-container show-content">
      <div className="carousel-content">
        <div className="carousel-slide-video">
          {isVideoVisible && isAnimationComplete && videoUrl ? (
            <>
              <ReactPlayer url={videoUrl} controls width="100%" height="100%" />
              <button className="close-video-button" onClick={handleCloseVideo}>
                Fermer la vidéo
              </button>
            </>
          ) : null}
        </div>

        {!isVideoVisible && imageUrl.length > 0 && (
          <div className="carousel-slide-image">
            <img src={imageUrl[currentIndex]} alt={`Slide ${currentIndex}`} />
          </div>
        )}
      </div>

      {showControls && (
        <div className="carousel-controls">
          <button className="carousel-button prev" onClick={handlePrev}>
            <FaArrowLeft />
          </button>
          <button className="carousel-button next" onClick={handleNext}>
            <FaArrowRight />
          </button>
        </div>
      )}

      {!isVideoVisible && videoUrl && (
        <button className="show-video-button" onClick={handleShowVideo}>
          Afficher la vidéo
        </button>
      )}
    </div>
  );
}

CarouselCard.propTypes = {
  imageUrl: PropTypes.arrayOf(PropTypes.string),
  videoUrl: PropTypes.string,
};
