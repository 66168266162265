// Import des styles spécifiques au composant NotesCardContent
import "./notes-card-content_style.scss";

// Import des bibliothèques nécessaires
import PropTypes from "prop-types"; // Pour la validation des propriétés
import hljs from "highlight.js"; // Pour la coloration syntaxique
import { useEffect } from "react";
import { CarouselCard } from "../../../../Globals_components/CarouselCard_components/CarouselCard";

// Fonction pour colorer et formater le texte entouré de !code!
const renderTextWithCodeHighlighting = (text) => {
  const regex = /!cd!(.*?)!cf!/gs;
  const parts = text.split(regex);

  return parts.map((part, index) => {
    if (index % 2 === 1) {
      // Les segments impairs sont du code
      const codeContent = part.trim(); // Supprime les espaces inutiles
      return (
        <pre key={index}>
          <code className="hljs">{codeContent}</code>
        </pre>
      );
    } else {
      // Les segments pairs sont du texte normal
      return (
        <p key={index} className="normal-text">
          {part}
        </p>
      );
    }
  });
};

export function NotesCardContent({ title, textAreas, subtitles, imageUrl }) {
  useEffect(() => {
    document.querySelectorAll("pre code").forEach((block) => {
      hljs.highlightElement(block);
    });
  }, [textAreas]);

  return (
    <div className="notes-content_container">
      <div className="notes-top-content">
        <div className="notes-content_top-text">
          {/* Affichage du titre de la carte */}
          <h3>{title}</h3>
        </div>
        <CarouselCard imageUrl={imageUrl} showVideo={false} />
      </div>

      <div className="notes-content_text">
        {subtitles &&
          textAreas &&
          subtitles.length === textAreas.length &&
          subtitles.map((subtitle, index) => (
            <div key={index} className="card-content_note-block">
              <h4 className="subtitle">{subtitle}</h4>
              <div className="content-card-note">
                {renderTextWithCodeHighlighting(textAreas[index])}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

// Définition des PropTypes pour valider les propriétés passées au composant
NotesCardContent.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  textAreas: PropTypes.arrayOf(PropTypes.string).isRequired, // Zones de texte à afficher (obligatoire)
  subtitles: PropTypes.arrayOf(PropTypes.string).isRequired, // Sous-titres associés aux zones de texte (obligatoire)
};
