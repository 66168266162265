import "./tabs__style.scss";
import PropTypes from "prop-types";

/**
 * Composant Tabs
 * @param {Object} props -
 * @param {string} props.textContent
 * @param {ReactElement} props.icon
 * @param {boolean} props.isActive
 * @returns {JSX.Element}
 */

export function Tabs({ textContent, icon, isActive }) {
  return (
    <div className={`tabs-container ${isActive ? "active" : ""}`}>
      <div className="tabs-background"></div>
      <span className="icon">{icon}</span>
      <span className="name">{textContent}</span>
    </div>
  );
}

Tabs.propTypes = {
  textContent: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  isActive: PropTypes.bool.isRequired,
};
