import { useMemo } from 'react';

/**
 * Hook personnalisé pour filtrer les cartes.
 * @param {Array} cards - Les cartes à filtrer.
 * @param {string} dateFilter - Le filtre de date.
 * @param {string|null} selectedCategory - La catégorie sélectionnée.
 * @returns {Array} - Les cartes filtrées et triées.
 */
const useFilteredCards = (cards, dateFilter, selectedCategory) => {
  return useMemo(() => {
    // Filtre les cards en fonction de leur catégorie
    const filteredCards = cards.filter((card) => {
      const categoryFilter = selectedCategory === null || selectedCategory === "Toutes" || 
                             (selectedCategory && card.categoriescard.includes(selectedCategory));
      return categoryFilter;
    });

    // Filtrées par date de création
    return filteredCards.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateFilter === "Les plus récents" ? dateB - dateA : dateA - dateB;
    });
  }, [cards, dateFilter, selectedCategory]);
};

export default useFilteredCards;