export const deleteCard = async (cardId, containerType, user, setCards , cards) => {
  if (!user) {
    throw new Error("Aucun utilisateur authentifié");
  }

  const token = await user.getIdToken();
  const url =
    containerType === "ContainerCode"
      ? `https://code-memories.vercel.app/api/codecards/${cardId}`
      : containerType === "ContainerNotes"
      ? `https://code-memories.vercel.app/api/notescards/${cardId}`
      : containerType === "ContainerRessources"
      ? `https://code-memories.vercel.app/api/ressourcescards/${cardId}`
      : null;

  console.log(`URL pour la suppression de la carte: ${url}`);

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setCards(cards.filter((card) => card.id !== cardId));
    } else {
      const errorText = await response.text();
      console.error(
        `Échec de la suppression de la carte: ${response.status} ${response.statusText} ${errorText}`
      );
      throw new Error("Échec de la suppression de la carte");
    }
  } catch (error) {
    console.error("Erreur lors de la suppression de la carte:", error);
    throw error;
  }
};
