// Import des styles spécifiques au composant ContainerCardContent
import "./container-card-content__style.scss";
import { FaLongArrowAltLeft } from "react-icons/fa";

// Import des composants pour afficher les détails des différentes cartes
import { CodeCardContent } from "./CardContent/CodeCardContent/CodeCardContent"; // Composant pour afficher le contenu des cartes de code
import { NotesCardContent } from "./CardContent/NotesCardContent/NotesCardContent"; // Composant pour afficher le contenu des cartes de notes
import { RessourcesCardContent } from "./CardContent/RessourcesCardContent/RessourcesCardContent"; // Composant pour afficher le contenu des cartes de ressources
import { useNavigate } from "react-router-dom";
// Import du hook useContext de React pour accéder au contexte
import { useContext } from "react";

// Import du contexte CardsContentSelectContext pour gérer la carte sélectionnée
import { CardsContentSelectContext } from "../../context/CardsContentSelectContext";
/**
 * Composant ContainerCardContent
 * Affiche le contenu détaillé de la carte sélectionnée en fonction de son type.
 */
export function ContainerCardContent() {
  // Accès au contexte pour obtenir la carte sélectionnée
  const { selectedCard } = useContext(CardsContentSelectContext);
  const navigate = useNavigate();

  /**
   * Redirige vers l'onglet de la card
   */
  const handleClickBack = () => {
    if (selectedCard.type === "ressources") {
      console.log(selectedCard.type);
      navigate("/Ressources");
    } else if (selectedCard.type === "code") {
      console.log(selectedCard.type);
      navigate("/Collection");
    } else if (selectedCard.type === "notes") {
      navigate("/docs-et-notes");
    }
  };
  // Vérifie si une carte est sélectionnée, sinon affiche un message d'erreur
  if (!selectedCard) {
    return <div>Erreur lors de laffichage des détails de la carte</div>;
  }

  return (
    <div className="container-card-content">
      <button className="btn-back" onClick={handleClickBack}>
        <FaLongArrowAltLeft /> Retour
      </button>
      {selectedCard.type === "code" ? (
        // Affiche le contenu pour une carte de type "code"
        <CodeCardContent
          title={selectedCard.title}
          technos={selectedCard.technos}
          imageUrl={selectedCard.imageUrl}
          subtitles={selectedCard.subtitles}
          textAreas={selectedCard.textAreas}
        />
      ) : selectedCard.type === "notes" ? (
        // Affiche le contenu pour une carte de type "notes"
        <NotesCardContent
          title={selectedCard.title}
          subtitles={selectedCard.subtitles}
          textAreas={selectedCard.textAreas}
        />
      ) : selectedCard.type === "ressources" ? (
        // Affiche le contenu pour une carte de type "ressources"
        <RessourcesCardContent
          title={selectedCard.title}
          subtitles={selectedCard.subtitles}
          textAreas={selectedCard.textAreas}
          videoUrl={selectedCard.videoUrl}
          imageUrl={selectedCard.imageUrl}
        />
      ) : null}
    </div>
  );
}
