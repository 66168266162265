import "./ressource-card-content_style.scss";
import PropTypes from "prop-types";
import hljs from "highlight.js";
import { useEffect } from "react";
import { CarouselCard } from "../../../../Globals_components/CarouselCard_components/CarouselCard";

// Fonction pour colorer et formater le texte entouré de !code!
const renderTextWithCodeHighlighting = (text) => {
  const regex = /!cd!(.*?)!cf!/gs;
  const parts = text.split(regex);

  return parts.map((part, index) => {
    if (index % 2 === 1) {
      // Les segments impairs sont du code
      const codeContent = part.trim(); // Supprime les espaces inutiles
      return (
        <pre key={index}>
          <code className="hljs">{codeContent}</code>
        </pre>
      );
    } else {
      // Les segments pairs sont du texte normal
      return (
        <p key={index} className="normal-text">
          {part}
        </p>
      );
    }
  });
};

export function RessourcesCardContent({
  title,
  textAreas,
  subtitles,
  videoUrl,
  imageUrl,
}) {
  useEffect(() => {
    document.querySelectorAll("pre code").forEach((block) => {
      hljs.highlightElement(block);
    });
  }, [textAreas]);

  return (
    <div className="ressource-content_container">
      <div className="ressource-top-content">
        <div className="ressource-content_top-text">
          <h3>{title}</h3>
        </div>
        <CarouselCard
          imageUrl={imageUrl}
          videoUrl={videoUrl}
          showVideo={true}
        />
      </div>

      <div className="ressource-content_text">
        {subtitles &&
          textAreas &&
          subtitles.length === textAreas.length &&
          subtitles.map((subtitle, index) => (
            <div key={index} className="card-contentressource-block">
              <h4 className="subtitle">{subtitle}</h4>
              <div className="content-card">
                {renderTextWithCodeHighlighting(textAreas[index])}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

RessourcesCardContent.propTypes = {
  title: PropTypes.string.isRequired,
  textAreas: PropTypes.arrayOf(PropTypes.string).isRequired,
  subtitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  videoUrl: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};
