import "./accueil-content__style.scss";

export function AccueilContent() {
  return (
    <div className="accueil-content">
      <div className="accueil-top-content">
        <h1>Qu&apos;est-ce que DevsMemories ?</h1>
        <p>
          DevsMemories est une application Web conçue pour simplifier le flux de
          travail des développeurs en centralisant la sauvegarde,
          l&apos;organisation et la recherche de code, de notes et de
          ressources. Elle permet de stocker des informations essentielles,
          d&apos;éviter les recherches répétitives et de travailler en toute
          sérénité.
        </p>
      </div>
      <section className="accueil-middle-content">
        <div className="accueil-explications-app">
          <h2>Contexte et Problématique</h2>
          <p>
            Les développeurs se retrouvent souvent avec une multitude
            d&apos;onglets ouverts et de fichiers dispersés (souvent la même
            information est recherchée plusieurs fois car on ne sais plus ou se
            trouve l&apos;onglet) , chacun contenant du code, des tutoriels ou
            des notes importantes ou bien des documentations, des articles ect.
            Refaire constamment les mêmes recherches, perdre des snippets
            précieux ou oublier où une information a été stockée peut devenir
            frustrant et chronophage.
          </p>
        </div>

        <div className="accueil-explications-objectif">
          <p>DevsMemories offre une solution centralisée et sécurisée pour :</p>
          <ul>
            <li>
              Sauvegarder des snippets de code en cours de création, trouvés en
              ligne ou générés par une IA.
            </li>
            <li>
              Organiser les snippets, documentations, notes et ressources par
              catégories, tags ou projets.
            </li>
            <li>
              Retrouver rapidement ces informations grâce à l&apos;application.
            </li>
            <li>
              Fermer sereinement tous les onglets, fichiers et supports
              contenant du code ou des informations, en sachant que tout est
              sauvegardé et facilement accessible.
            </li>
            <li>
              Stocker du contenu sur mesure avec &quot;vos mots&quot;. Que vous
              suiviez un tutoriel ou que vous notiez des informations
              importantes, l&apos;application vous permet de les stocker en
              utilisant vos propres termes, facilitant ainsi la compréhension et
              l&apos;utilisation future.
            </li>
          </ul>
        </div>

        <div className="accueil-explications-fonctionnalitees">
          <ul>
            <li>
              <strong>Stockage de Code :</strong> Sauvegardez facilement vos
              snippets, organisez-les, et retrouvez-les rapidement.
              <p>
                <em>Scénarios d&apos;utilisation :</em>
              </p>
              <ul>
                <li>
                  Vous travaillez sur une logique complexe en PHP que vous
                  souhaitez réutiliser. Enregistrez-la dans DevsMemories pour la
                  retrouver facilement sans craindre de la perdre.
                </li>
                <li>
                  Vous avez de nombreux onglets ouverts sur des sites comme
                  CodePen ou avec des snippets trouvés en ligne. Sauvegardez-les
                  dans l&apos;application, fermez vos onglets et continuez à
                  travailler sans distraction.
                </li>
                <li>
                  Vous générez un code via une IA pour résoudre un problème
                  spécifique. Sauvegardez-le directement dans l&apos;application
                  pour le retrouver plus tard si vous en avez besoin, sans
                  devoir regénérer le code.
                </li>
              </ul>
            </li>

            <li>
              <strong>Gestion de Notes :</strong> Enregistrez des
              documentations, notes ou fichiers tels que des README de projets.
              <p>
                <em>Scénarios d&apos;utilisation :</em>
              </p>
              <ul>
                <li>
                  Vous oubliez souvent les commandes Git et devez les rechercher
                  à chaque fois. Stockez-les dans DevsMemories pour y accéder
                  facilement. L&apos;avantage est que vous les décrivez avec vos
                  propres mots, ce qui facilite leur mémorisation.
                </li>
                <li>
                  Vous rédigez une première version d&apos;un README de projet.
                  Enregistrez-la dans l&apos;application pour la retrouver plus
                  tard et la modifier si besoin.
                </li>
                <li>
                  Vous consultez souvent la documentation officielle de
                  JavaScript pour l&apos;utilisation de l&apos;objet `Date`.
                  Sauvegardez la partie pertinente dans DevsMemories, rajoutez
                  des explications avec vos mots et évitez de retourner sur la
                  documentation pour la même chose.
                </li>
              </ul>
            </li>

            <li>
              <strong>Stockage de Ressources :</strong> Sauvegardez et
              catégorisez des ressources pour un accès simplifié, notamment des
              cours en ligne, des tutoriels vidéo ou écrits.
              <p>
                <em>Scénarios d&apos;utilisation :</em>
              </p>
              <ul>
                <li>
                  Vous suivez un tutoriel vidéo complexe et souhaitez y revenir
                  plus tard. Enregistrez le lien, vos notes et les exemples de
                  code dans DevsMemories pour les retrouver facilement et
                  poursuivre votre apprentissage.
                </li>
                <li>
                  Vous avez trouvé un article détaillant une technique avancée
                  et souhaitez le conserver. Sauvegardez-le dans
                  l&apos;application pour le retrouver sans devoir refaire la
                  recherche.
                </li>
                <li>
                  Vous avez plusieurs cours en ligne ouverts dans des onglets.
                  Centralisez-les dans DevsMemories, fermez vos onglets, et
                  accédez-y facilement quand vous en avez besoin.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>

      <section className="accueil-pourquoi-devmemories">
        <h2>Pourquoi DevMemories ?</h2>
        <p>
          DevMemories est conçu pour être l&apos;outil indispensable des
          développeurs en leur offrant :
        </p>
        <ul>
          <li>
            <strong>Centralisation des Informations :</strong> Toutes vos
            données importantes sont stockées en un seul endroit. Plus besoin de
            jongler entre des dizaines d&apos;onglets ouverts ou de craindre de
            perdre des informations cruciales.
          </li>
          <li>
            <strong>Accès Simplifié :</strong> Grâce à une interface intuitive,
            accédez rapidement à vos codes, notes et ressources, directement
            depuis votre bureau, sans perdre de temps à les rechercher.
          </li>
          <li>
            <strong>Sécurité et Sérénité :</strong> En sauvegardant vos
            informations critiques, vous travaillez sans crainte de perdre des
            données essentielles, même en fermant des onglets ou en cas de
            problème de navigateur.
          </li>
          <li>
            <strong>Productivité Améliorée :</strong> En stockant les solutions
            trouvées pour des problèmes complexes ou des codes générés, vous
            gagnez du temps et évitez de devoir refaire des recherches
            répétitives.
          </li>
          <li>
            <strong>Fermeture Sereine des Supports :</strong> Une fois votre
            code ou vos informations sauvegardés dans DevMemories, vous pouvez
            fermer sereinement vos onglets de navigateur, vos fichiers Visual
            Studio ou tout autre support, en sachant que tout est en sécurité
            dans l&apos;application.
          </li>
        </ul>
        <p className="resume">
          En résumé, DevsMemories est plus qu&apos;une simple application :
          c&apos;est une véritable boîte à outils pour tout développeur
          cherchant à optimiser son flux de travail, éviter les pertes de
          données et simplifier sa gestion quotidienne des informations.
        </p>
      </section>
    </div>
  );
}
