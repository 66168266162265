import { memo, useCallback } from "react";
import { useSearch } from "../../../context/SearchContext";
import { HiMiniMagnifyingGlass } from "react-icons/hi2";
import "./search-bar__style.scss";

export const SearchBar = memo(function SearchBar() {
  const { searchTerm, setSearchTerm } = useSearch();

  const handleSearchChange = useCallback(
    (event) => {
      setSearchTerm(event.target.value);
    },
    [setSearchTerm]
  );

  return (
    <div className="search_bar-container">
      <span className="magnify_glass">
        <HiMiniMagnifyingGlass />
      </span>
      <input
        className="search_bar"
        type="search"
        placeholder="Recherche par mots clés (exemple : css, carrousel, react.js...)"
        value={searchTerm}
        onChange={handleSearchChange}
      />
    </div>
  );
});
