import { HelpContent } from "../../components/Pages_components/Help_components/HelpContent";
import "../global_pages.scss";
import { useEffect } from "react";

export function Aide() {

  useEffect(()=>{
    document.title = "Devs Memories | Aide"
  })

  return (
    <div className="page_content">
      <HelpContent />
    </div>
  );
}
