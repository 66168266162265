import { useState } from "react";

const useModalToggle = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);

  /**
   * Ferme le modal pour ajouter une nouvelle carte.
   * @function handleCloseModal
   */
  const handleCloseModal = () => setIsModalOpen(false);

  return { isModalOpen, handleOpenModal, handleCloseModal };
};

export default useModalToggle;
