export const fetchCategories = async (user, containerType) => {
  if (!user) {
    throw new Error("Aucun utilisateur connecté");
  }
  const token = await user.getIdToken();
  let url = "";

  switch (containerType) {
    case "ContainerCode":
      url = "https://code-memories.vercel.app/api/categoriescode";
      break;
    case "ContainerNotes":
      url = "https://code-memories.vercel.app/api/categoriesnotes";
      break;
    case "ContainerRessources":
      url = "https://code-memories.vercel.app/api/categoriesressources";
      break;
    default:
      throw new Error("Type de conteneur inconnu");
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Erreur de récupération des catégories");
  }
};
